<template>
    <div>
        <navbar :items="navItems"/>
            <form id="add_host_form" class="form-horizontal" @submit.prevent="addingHost">
                <div class="row" v-if="has_error">
                    <div class="alert alert-danger">
                        {{error_msg}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="nom">Propriétaire</label>
                            <select v-select="{placeholder: 'Selectionnez le propriétaire'}" class="form-control" v-model="owner">
                                <option/>
                                <option  v-for="(user, index) in users" :value="user.uid" :key="index">{{user.fullname}} - ({{resolveType(user.typeCompte)}})</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="lat">Latitude</label>
                            <input type="text" class="form-control" name="lat" id="lat" v-model="lat"/>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="long">Longitude</label>
                            <input type="text" class="form-control" name="long" id="long" v-model="long"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="model">Modèle</label>
                            <select v-select="{placeholder: 'Selectionnez le modèle'}" class="form-control" v-model="model">
                                <option/>
                                <option  v-for="(m, index) in models" :value="m.uid" :key="index">{{m.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="model">Version</label>
                            <select v-select="{placeholder: 'Selectionnez la version'}" class="form-control" v-model="version">
                                <option/>
                                <option  v-for="(v, index) in availableVersions" :value="v.uid" :key="index">{{v.name}} [{{v.libelle}}]</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="model">Firmware Logiciel</label>
                            <select v-select="{placeholder: 'Selectionnez le firmware'}" class="form-control" v-model="firmware">
                                <option/>
                                <option  v-for="(f, index) in availableFirmwares" :value="f.uid" :key="index">{{f.libelle}}  <span v-if="f.deprecat || f.recommanded">[{{recommandedFirmware(f.uid)}}] </span></option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                            <div class="form-group">
                            <label for="imei">Imei</label>
                            <input type="text" class="form-control" name="imei" id="imei" v-model="imei"/>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="numero">Numero GSM</label>
                            <input type="text" class="form-control" name="numero" id="numero" v-model="numero"/>
                        </div>
                    </div>
                    <div class="col"  v-if="hasClient">
                        <div class="form-group">
                            <label for="nbClient">Nombre Sortie</label>
                            <input type="number" class="form-control" name="nb_client" id="nb_client" v-model="nb_client"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label for="options"> Options </label>
                        </div>
                    </div>
                    <div class="col-2">
                        <label class="checkbox checkbox-primary">
                            <input type="checkbox" name="feedback" v-model="feedback">
                            <span></span> Remonté des données
                        </label>
                    </div>
                    <div class="col-2">
                        <label class="checkbox checkbox-primary">
                            <input type="checkbox" name="mobilepay" v-model="mobilePayment">
                            <span></span> Paiement mobile
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="checkbox checkbox-primary">
                            <input type="checkbox" name="mobilepay" v-model="towerController">
                            <span></span> Controle du niveau du château
                        </label>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px;"  v-if="towerController">
                    <div class="col">
                            <div class="form-group">
                            <label for="imei">Volume du château d'eau(m3)</label>
                            <input type="text" class="form-control" name="tower-volume" id="tower-volume" v-model="towerVolume"/>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="numero">Hauteur du château d'eau(m)</label>
                            <input type="text" class="form-control" name="tower-hauteur" id="tower-hauteur" v-model="towerHauteur"/>
                        </div>
                    </div>
                </div>
                <div class="row">
               <div class="col text-right">
                   <input type="submit" class="btn btn-primary" value="Ajouter">
               </div>
           </div>
            </form>
    </div>
</template>
    

<script>
import {mapGetters} from 'vuex'
import {Select} from '../../directive/select'
import {ADD_HOST} from '../../graphql/host'
import Navbar from '../../components/navBar.vue'
export default {
    directives: { Select },
    components: {Navbar},
    data() {
       return {
        navItems: [
            {name: 'Liste des sites', route: 'hosts'},
            {name: 'Ajout'}
        ],
           owner: null,
           model: null,
           version: null,
           firmware: null,
           imei: null,
           numero: null,
           nb_client: 1,
           long: null,
           lat: null,
           hasClient: false,
           has_error: false,
           error_msg: null,
           feedback: false,
           mobilePayment: false,
           towerController: false,
           towerHauteur: 0,
           towerVolume:0
       } 
    },
    watch: {
        model(){
            this.version = null
            this.firmware = null
            let selectedModel = this.models.find(item => item.uid === this.model)
            this.hasClient = selectedModel.hasClient
        }
    },
    methods: {
        resolveType(typeUID){
            let type = this.typeComptes.find(item => item.uid === typeUID)
            return type.libelle
        },
        recommandedFirmware(firmwareUID){
            let firmware = this.firmwares.find(item => item.uid === firmwareUID)
            return firmware.recommanded ? 'Recommandé': firmware.deprecat? 'Obsolète': ''
        },
        addingHost(){
            console.log('adding host')
            this.$apollo.mutate({
                mutation: ADD_HOST,
                variables: {
                    "host": {
                        "imei": this.imei,
                        "numero": this.numero,
                        "nbClient": parseInt(this.nb_client),
                        "long": parseFloat(this.long),
                        "lat": parseFloat(this.lat),
                        "owner": this.owner,
                        "version": this.version,
                        "firmware": this.firmware,
                        "model": this.model,
                        "hasTowerController": this.towerController,
                        "hauteur": parseFloat(this.towerHauteur),
                        "volume": parseFloat(this.towerVolume),
                        "options": JSON.stringify({
                            "feed_back": this.feedback,
                            "mobile_payment": this.mobilePayment
                        })
                    }
				},
                update: (store, { data  }) => {
                    console.log(data)
				},
            }).then((data) => {
                console.log(data)
                this.$router.push('hosts')
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
        
    },
    computed:{
        ...mapGetters({
            users: 'auth/users',
            typeComptes: 'auth/typeComptes',
            models: 'host/models',
            versions: 'host/versions',
            firmwares: 'host/firmwares'
        }),
        availableVersions () {
            if(this.model === null) return []
            return [...this.versions].filter(item => item.model === this.model)
        },
        availableFirmwares () {
            if(this.version === null) return []
            return [...this.firmwares].filter(item => item.version === this.version)
        }
    }
}
</script>

<style scoped>

</style>

import jQuery from 'jquery'
import Vue from 'vue'

window.jQuery = jQuery
const $ = jQuery

var updateFunction = (el, binding) => {
  let options = binding.value || {}
  $(el).select2(options).on('select2:select', (e) => {
    el.dispatchEvent(new Event('change', { target: e.target }))
  })
}

export const Select = {
  inserted: updateFunction,
  update: updateFunction
}
Vue.directive('select', Select)
